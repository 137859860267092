import React from "react";
import FormularioLogin from "../../Components/FormularioLogin";
import * as S from "./style"

function Login(){

    return(
        <S.LoginWrapper>
            <FormularioLogin />
        </S.LoginWrapper>
    )
}

export default Login;