import styled from "styled-components";

export const FormularioLoginStyle = styled.div`
  padding: 2rem 0;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 40%;

  .btn {
    margin-top: 1rem;
  }

  .form-group {
    padding-top: 1rem;
  }

  @media (min-width: 480px) {
  }

  @media (min-width: 760px) {
  }

  @media (min-width: 1200px) {
  }
`;
