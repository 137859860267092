import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

  const api = axios.create({
    baseURL: baseURL,
    // baseURL: "https://scadu.clubehost.com:8443",
    withCredentials: true,
  });

  class ApiService {
    constructor(apiUrl) {
      this.apiUrl = apiUrl;
    }

    static registrarToken(token) {
      if (token) {
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      }
    }

    post(url, objeto) {
      const requestUrl = `${this.apiUrl}${url}`;
      return api.post(requestUrl, objeto);
    }

    postParam(url, objeto) {
      const requestUrl = `${this.apiUrl}${url}/?file=${objeto}`;
      return api.post(requestUrl, objeto);
    }

    put(url, objeto) {
      const requestUrl = `${this.apiUrl}${url}`;
      return api.put(requestUrl, objeto);
    }

    delete(url) {
      const requestUrl = `${this.apiUrl}${url}`;
      return api.delete(requestUrl);
    }

    delete(url, parametros) {
      const requestUrl = `${this.apiUrl}${url}`;
      return api.delete(requestUrl, parametros);
    }

    getParametros(url, parametros) {
      const requestUrl = `${this.apiUrl}${url}`;
      return api.get(requestUrl, parametros);
    }
    get(url) {
      const requestUrl = `${this.apiUrl}${url}`;
      return api.get(requestUrl);
    }

    gerarRelatorioGet(url) {
      const requestUrl = `${this.apiUrl}${url}`;
      return api.get(requestUrl, {responseType: "blob"});
    }

    gerarRelatorioSeguroEspecial(url) {
      const requestUrl = `${this.apiUrl}${url}`;
      return api.get(requestUrl, {responseType: "blob"});
    }

    getAll(url) {
      const requestUrl = `${this.apiUrl}${url}`;
      return api.get(requestUrl);
    }

  }

export default ApiService;
