import * as S from "./style"; //Importando conteúdo do styled component
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import AuthService from "../../Services/authService";
import { useContext } from "react";
import { FiltrosContext } from "../../App";
import { Image } from "react-bootstrap";

export default function NavbarMenu(props) {
  const navigate = useNavigate();
  const [filtros, setFiltros] = useContext(FiltrosContext);

  const limparFiltros = (e) => {
    setFiltros({
      nome: "",
      cpf: "",
      dataInicio: null,
      dataFim: null,
    })
  }

  const sair = (e) => {
    AuthService.removerUsuarioAutenticado();
    limparFiltros();

    navigate("/");
  };

  return (
    <S.NavbarWrapper>
      <Navbar expand="lg">
        <Container>
          <Link to="/" onClick={limparFiltros}>
            <Navbar.Brand><Image src="em pe-1.png" className="img-brand" /></Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto navbar-links">
              <Link to="/" className="nav-item" onClick={limparFiltros}>
                <Nav.Item>Home</Nav.Item>
              </Link>
              {AuthService.isUsuarioAutenticado() && (
                <NavDropdown title="Pessoas" id="basic-nav-dropdown">
                  <Link to="/cadastro-pessoas" onClick={limparFiltros}>
                    <NavDropdown.Item as={"div"}>Cadastro</NavDropdown.Item>
                  </Link>

                  <Link to="/buscar-pessoas" onClick={limparFiltros}>
                    <NavDropdown.Item as={"div"}>Pesquisa</NavDropdown.Item>
                  </Link>
                </NavDropdown>
              )}

              {!AuthService.isUsuarioAutenticado() && (
                <Link to="/login" className="nav-item" onClick={limparFiltros}>
                  <Nav.Item>Login</Nav.Item>
                </Link>
              )}

              {AuthService.isUsuarioAutenticado() && (
                <NavDropdown title={AuthService.obterItemUsuarioAutenticado()} id="basic-nav-dropdown" className="fundo">
                  <Link to="/cadastro-pessoas" onClick={limparFiltros}>
                    <Nav.Link onClick={sair}>Sair</Nav.Link>
                  </Link>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </S.NavbarWrapper>
  );
}
