import ApiService from "./Api";

class FileService extends ApiService{
    constructor(){
        super("/api/file")
    }

    salvar(file){
        return this.post("/uploadMultipleFiles", file);
    }

    buscarPerfil(nome){
        return this.getParametros("/", { params: { caminho: nome } })
    }

    deleteImagem(nome){
        return this.delete("/", { params: { nome: nome } })
    }
}

export default FileService