import React from "react";
import { useLocation } from "react-router-dom";
import FormDetalhar from "../../Components/FormDetalhar";
import * as S from "./style"

function DetalharPessoa(){
    const location = useLocation();
    let idPessoa = false;
    let paginaAtual = null;
    if(location.state != null){
        idPessoa = location.state.idPessoa;
        paginaAtual = location.state.paginaAtual;
     }
    return(
        <S.DetalharPessoasWrapper>
            <FormDetalhar idPessoa={idPessoa} paginaAtual={paginaAtual}/>
        </S.DetalharPessoasWrapper>
    )
}

export default DetalharPessoa;