import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import moment from 'moment'
import 'moment/locale/pt'
import imagem from "../../../Imagens/brasaoNacionaljpg.jpg";


function PescadorProfissional(pessoa){

    const ima = imagem;
    
    moment.locale('pt-br');
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const now = moment().format('LL');

    const genero =[
        "( )F  (X)M",
        "(X)F  ( )M",
        "( )F  ( )M"
    ]

    function convertData(data){
        var ano  = data.split("-")[0];
        var mes  = data.split("-")[1];
        var dia  = data.split("-")[2];
    
        return ("0"+dia).slice(-2) + '/' + ("0"+mes).slice(-2) + '/' + ano;
      }


//     const reportTitle = [

        
//         {

//             image: ima,
//             margin: [60, 20, 0, 0]
            
//         },
//         // {
//         // text: 'MINISTÉRIO DA AGRICULTURA, PECUÁRIA E ABASTECIMENTO. ' + 
//         //         ' \n SECRETARIA DE AQUICULTURA E PESCA.',
        
//         // fontSize: 10,
//         // bold: true,
//         // margin: [200, 20, 0, 45], //lef, top, right, bottom
//         // },

// ];

    const details = [
        // {
        //     text: 'NUP:',
        //     margin: [200, 40, 0, 0],
        // },

        {
            margin: [218, 20, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: 'NUP:'},
                    {text: 'a', color: 'white'},
                    {text: 'a', color: 'white'},
                    {text: 'a', color: 'white'},
                    {text: 'a', color: 'white'},
                    {text: 'a', color: 'white'},
                    {text: 'a', color: 'white'},
                    {text: 'a', color: 'white'},
                    {text: 'a', color: 'white'},
                    {text: 'a', color: 'white'},
                    {text: 'a', color: 'white'},
                    {text: 'a', color: 'white'},
                    {text: 'a', color: 'white'},
                    {text: 'a', color: 'white'},
                    {text: 'a', color: 'white'},
                    {text: 'a', color: 'white'},
                    {text: 'a', color: 'white'},
                    {text: 'a', color: 'white'},
                    {text: 'a', color: 'white'}],
                    
                
                
                ]
            }
        },

        {
            margin: [20, 40, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:['auto', 400, 80],
                heights: [20, 20, 70],
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "0", bold: true, fontSize: 10}, 
                    {text: "IDENTIFICAÇÃO DO INTERESSADO*", bold: true, fontSize: 10},
                    {text: "Foto\n3 x 4", bold: true, fontSize: 10, rowSpan: 3, alignment: 'center', margin: [0, 45, 0, 0]}
                    ],
                    [{text: `01-Nome do Interessado:`, bold: true, fontSize: 10, colSpan: 2}, 
                    {},{}],
                    [{text: `${pessoa.nome}`, bold: true, fontSize: 10, colSpan: 2}, 
                    {},{}],
                
                
                ]
            }
        },

        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:['auto', 367],
                // heights: ['auto', 40],
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "02-CPF (Somente números): ", bold: true, fontSize: 10},
                    {text: "03-Nacionalidade ", bold: true, fontSize: 10}
                    ],
                    [{text: `${pessoa.cpf}`, bold: true, fontSize: 10},
                    {text: `( X ) Brasileira ( ) Naturalizado \n ( ) Estrangeiro (DATA VALIDADE VISTO) __/__/__/`, bold: true, fontSize: 10}, 
                    ],
                
                
                ]
            }
        },

        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:[100, 196.5, 100, 80],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "04-Nº do Doc. Oficial de Identificação ", bold: true, fontSize: 10},
                    {text: "05-Órgão Emissor/UF: ", bold: true, fontSize: 10},
                    {text: "06-Data de emissão: ", bold: true, fontSize: 10},
                    {text: "07-Data de nascimento: ", bold: true, fontSize: 10}
                    ],
                    [{text: `${pessoa.rg}`, bold: true, fontSize: 10},
                    {text: `SSP-MA`, bold: true, fontSize: 10}, 
                    {text: `${convertData(pessoa.dataEmissao)}`, bold: true, fontSize: 10},
                    {text: `${convertData(pessoa.dataNascimento)}`, bold: true, fontSize: 10},  
                    ],
                
                
                ]
            }

            
        },

        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:[100, 100, 285.5],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "08-Sexo: ", bold: true, fontSize: 10},
                    {text: "09-Nome Pai: ", bold: true, fontSize: 10},
                    {text: `${pessoa.nomePai}`, bold: true, fontSize: 10},
                    ],
                    [{text: genero[pessoa.genero - 1], bold: true, fontSize: 10},
                    {text: `10-Nome Mae: `, bold: true, fontSize: 10}, 
                    {text: `${pessoa.nomeMae}`, bold: true, fontSize: 10}, 
                    ],
                
                
                ]
            }

            
        },

        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:['auto', 100, 160, 165],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "11-Apelido: ", bold: true, fontSize: 10},
                    {text: ``, bold: true, fontSize: 10},
                    {text: `12-NºPIS/PASEP/NIT/NIS: `, bold: true, fontSize: 10},
                    {text: `${pessoa.numeroNit !== null ? pessoa.numeroNit : ""}`, bold: true, fontSize: 10},
                    ],
                    
                ]
            }

            
        },

        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:['auto', 488],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "B", bold: true, fontSize: 10},
                    {text: `ENDEREÇO RESIDENCIAL*: `, bold: true, fontSize: 10},
                    ],
                    
                ]
            }

            
        },

        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:[503.7],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "13- Endereço completo (Rua, Avenida, número, etc): ", bold: true, fontSize: 10},
                    ],
                    [{text: `${pessoa.endereco} Nº ${pessoa.numeroCasa}`, bold: true, fontSize: 10},
                    ],
                    
                ]
            }

            
        },

        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:[100, 100, 165, 112],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "14-CEP: ", bold: true, fontSize: 10},
                    {text: `15-UF: `, bold: true, fontSize: 10},
                    {text: `16-Município: `, bold: true, fontSize: 10},
                    {text: `17-Bairro: `, bold: true, fontSize: 10},
                    ],
                    [{text: `${pessoa.cep}`, bold: true, fontSize: 10},
                    {text: `${pessoa.estado}`, bold: true, fontSize: 10},
                    {text: `${pessoa.municipio}`, bold: true, fontSize: 10},
                    {text: `${pessoa.bairro}`, bold: true, fontSize: 10},
                    ],
                    
                ]
            }
        },
        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:[100, 394.5],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "18-Telefone:º11", bold: true, fontSize: 10},
                    {text: `19- E-mail: `, bold: true, fontSize: 10},
                    ],
                    [{text: `${pessoa.celular}`, bold: true, fontSize: 10},
                    {text: `${pessoa.email}`, bold: true, fontSize: 10},
                    ],
                    
                ]
            }
        },
        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:['auto', 488],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "C", bold: true, fontSize: 10},
                    {text: `CLASSIFICAÇÃO DA CATEGORIA DO PESCADOR PROFISSIONAL*: `, bold: true, fontSize: 10},
                    ],
                    
                ]
            }

            
        },
        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:[503.7],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "20- Categoria: ", bold: true, fontSize: 10},
                    ],
                    [{text: `( X ) Artesanal        ( ) Industrial`, bold: true, fontSize: 10},
                    ],
                    
                ]
            }

        },
        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:['auto', 488],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "D", bold: true, fontSize: 10},
                    {text: `QUALIFICAÇÃO DA FORMA DE ATUAÇÃO PRETENDIDA*: `, bold: true, fontSize: 10},
                    ],
                    
                ]
            }

            
        },
        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:[165, 100, 100, 112],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "21-Forma: ", bold: true, fontSize: 10},
                    {text: `22-Nome da Embarcação: `, bold: true, fontSize: 10},
                    {text: `23-Nº RGP: `, bold: true, fontSize: 10},
                    {text: `24-AB: `, bold: true, fontSize: 10},
                    ],
                    [{text: "( )Embarcado  (X)Desembarcado\n(PREENCHER 22,23 e 24)\n(PULAR 22, 23 e 24)", bold: true, fontSize: 10},
                    {text: ``, bold: true, fontSize: 10},
                    {text: ``, bold: true, fontSize: 10},
                    {text: ``, bold: true, fontSize: 10},
                    ],
                    
                ]
            }
        },

        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:[503.7],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "25- Produtos de Pesca Pretendidos: ", bold: true, fontSize: 10},
                    ],
                    [{text: `(X)Peixes  (X) Crustáceos  ( )Mariscos  ( )Algas  ( )Outros`, bold: true, fontSize: 10},
                    ],
                    
                ]
            }
            
        },
        

        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:[200, 294.4],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "26- Área que pretende realizar a pesca: ", bold: true, fontSize: 10},
                    {text: `27- Local de pesca: `, bold: true, fontSize: 10},
                    ],
                    [{text: "( ) Mar  (X)Estuário ( )Rio\n( ) Lago/Lagoa ( )Reservatório\n ( )Açude", bold: true, fontSize: 10},
                    {text: `IGARAPE DO PORTO DO BRAGA `, bold: true, fontSize: 10},
                    ],
                    
                ]
            }

            
        },

        {
            text: "sdsdksdjsdj",
            fontSize: 10,
            bold: true,
            color: 'white',
            margin: [0, 40, 0, 0]
        },

        {
            margin: [20, 30, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:['auto', 488],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "E", bold: true, fontSize: 10},
                    {text: `DECLARAÇÃO DE VÍNCULO EMPREGATIVO OU OUTRA FONTE DE RENDA*: `, bold: true, fontSize: 10},
                    ],
                    
                ]
            }

            
        },

        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:[180, 314],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "28- Vínculo empregativo: ", bold: true, fontSize: 10},
                    {text: `29- Aposentado: `, bold: true, fontSize: 10},
                    ],
                    [{text: "( )Sim  (X)Não", bold: true, fontSize: 10},
                    {text: `( )Sim  (X)Não `, bold: true, fontSize: 10},
                    ],
                    
                ]
            }

            
        },
        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:['auto', 488.5],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "F", bold: true, fontSize: 10},
                    {text: `ESCOLARIDADE*: `, bold: true, fontSize: 10},
                    ],
                    
                ]
            }

            
        },
        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:[503.5],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "30- Classificação", bold: true, fontSize: 10},
                    ],
                    [
                    {
                        text: `(   ) 1ª à 4ª Série incompleta/Ensino Fundamental       (  ) 2º Grau Completo/Ensino Médio\n` +
                              `(   ) 1ª à 4ª Série completa/Ensino Fundamental          (  ) Ensino Técnico Incompleto\n`+
                              `(   ) 5ª à 9ª Série incompleta/Ensino Fundamental       (  ) Ensino Técnico Completo\n` +
                              `(   ) 5ª à 9ª Série completa/Ensino Fundamental          (  ) Ensino Superior incompleto\n`+
                              `(   ) 2° Grau Incompleto/Ensino Médio                            (  ) Ensino Superior completo`, 
                        bold: true, fontSize: 10},
                    ],
                    
                ]
            }

            
        },
        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:[503.5],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "31- Você se considera: ", bold: true, fontSize: 10},
                    ],
                    [
                    {
                        text: `(X) Completamente Alfabetizado   ( ) Capaz apenas de assinar seu nome   ( ) Não alfabetizado`, 
                        bold: true, fontSize: 10},
                    ],
                    
                ]
            }

            
        },
        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:['auto', 488],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "G", bold: true, fontSize: 10},
                    {text: `IDENTIFICAÇÃO DA ENTIDADE REPRESENTATIVA DE CLASSE* `, bold: true, fontSize: 10},
                    ],
                    
                    
                ]
            }

            
        },
        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:[200, 294.4],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "32- Filiado a entidade representativa: ", bold: true, fontSize: 10},
                    {text: `33- Tipo de entidade: `, bold: true, fontSize: 10},
                    ],
                    [{text: "(X) Sim   ( )Não", bold: true, fontSize: 10},
                    {text: `(X) Colônia ( )Sindicato ( )Associação  ( ) Outros `, bold: true, fontSize: 10},
                    ],
                    
                    
                ]
            }

            
        },
        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:[180, 164, 141.5],
                // heights: 20,
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "34- Nome da entidade à qual é filiada: ", bold: true, fontSize: 10},
                    {text: `35- CNPJ da Entidade: `, bold: true, fontSize: 10},
                    {text: `36- UF Entidade: `, bold: true, fontSize: 10},
                    ],
                    [{text: "COLONIA DE PESCADORES Z-53", bold: true, fontSize: 10},
                    {text: `02.154.454/0001-42 `, bold: true, fontSize: 10},
                    {text: `MA`, bold: true, fontSize: 10},
                    ],
                    
                    
                ]
            }

            
        },


        {
            text: "*= Áreas de campos que devem ser totalmente preenchidos.",
            fontSize: 8,
            margin: [20, 10, 0, 0]
        },

        {
            text: "DECLARAÇÃO:",
            fontSize: 8,
            alignment: 'center',
            margin: [0, 10, 0, 0]
        },

        {
            margin: [20, 10, 20, 0],
            fontSize: 8,
            text: [
                'Declaro, sob responsabilidade civil e penal, que as informações declaradas no '+
                '"Formulário de Requerimento de Licença de Pescador Profissional" são verdadeiras ' + 
                'e que estou ciente que a informações não verídicas declaradas, implicarão em penalidades '+
                'previstas no Artigo 299 do Código Penal (Falsidade ideológica), além de sansões civis e '+ 
                'administrativas cabíveis.',
            ]
        },

        {
            text: '"Art. 299 do Código Penal Brasileiro -Omitir, em documento público ou particular,'+
                          ' declaração que dele devia constar, ou nele inserir ou fazer inserir declaração falsa '+
                          'ou diversa da que devia ser escrita, com fim de prejudicar direito, criar obrigação '+
                          'ou alterar a verdade sobre fato juridicamente relevante: \n'+
                          'Pena- reclusão, de um a cinco anos, e multa, se o documento é '+
                          'público, e reclusão de 1 a 3 anos, e multa, se o documento é particular."', 
            margin: [80, 0, 20, 0],
            fontSize: 8,
        },

        {
            margin: [0, 15, 0, 0],
            alignment: 'center',
            fontSize: 9,
            text: [
                {text: `Raposa, `, decoration: 'underline'},
                {text: `${now}`, decoration: 'underline'}
            ],
        },
        {
            margin: [0, 0, 0, 0],
            alignment: 'center',
            fontSize: 9,
            text: [
                {text: `Local, `, decoration: 'underline'},
                {text: `Data`, decoration: 'underline'}
            ],
        },

        {
            text: '___________________________________________________________________', 
            alignment: 'center',
            margin: [0, 30, 0, 0],
        },

        {
            text: 'ASSINATURA DO INTERESSADO',
            alignment: 'center',
            fontSize: 9,
            margin: [0, 20, 0, 0],

        },

        {
            text: 'ASSINATURA A ROGO EM CASO DO INTERESSADO ANALFABETO E TESTEMUNHAS: ',
            alignment: 'center',
            fontSize: 9,
            margin: [0, 17, 0, 120],

        },

        {
            text: "sdsdksdjsdj",
            fontSize: 10,
            bold: true,
            color: 'white',
            margin: [0, 40, 0, 0]
        },

        {
            margin: [100, 20, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:[90],
                heights: [70],
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "Polegar direito", fontSize: 8, alignment: 'center',},
                    ],
                    
                    
                ]
            }

            
        },

        {
            text: 'NOME:______________________________________________________________ ',
            fontSize: 9,
            margin: [100, 10, 0, 0],

        },
        {
            text: 'CARTEIRA DE IDENTIDADE:_____________________CPF:_________________ ',
            fontSize: 9,
            margin: [100, 0, 0, 0],

        },
        {
            text: 'ASSINATURA_________________________________________________________',
            fontSize: 9,
            margin: [100, 0, 0, 0],

        },
        {
            text: 'NOME:______________________________________________________________ ',
            fontSize: 9,
            margin: [100, 10, 0, 0],

        },
        {
            text: 'CARTEIRA DE IDENTIDADE:_____________________CPF:_________________ ',
            fontSize: 9,
            margin: [100, 0, 0, 0],

        },
        {
            text: 'ASSINATURA_________________________________________________________',
            fontSize: 9,
            margin: [100, 0, 0, 0],

        },
        
        {
            text: 'O requerente apresentou a documentação completa em ____/____/____ , de acordo '+
            'com a Instrução Normativa MPA N°6/2012.',
            fontSize: 9,
            margin: [20, 10, 20, 0],

        },

        {
            text: '___________________________________________________________________', 
            alignment: 'center',
            margin: [0, 30, 0, 0],
        },

        {
            text: 'ASSINATURA E CARIMBO DO RESPONSÁVEL DA SAP',
            alignment: 'center',
            fontSize: 9,
            margin: [0, 0, 0, 0],

        },

        {
            text: 'IMPRIMIR E DESTACAR AO INTERESSADO',
            alignment: 'center',
            fontSize: 6,
            color: 'red',
            margin: [0, 20, 0, 0],

        },

        {
            text: '___________________________________________________________________________________________________', 
            alignment: 'center',
            margin: [0, 0, 0, 0],
        },

        // {

        //     image:ima,
        //     margin: [50, 10, 0, 0],
        //     width: 60,
        
        // },

        {
            margin: [0, 10, 0, 0],
            image: ima,
            alignment: 'center',
            width: 60,
            
        },

        {
            text: 'SECRETARIA ESPECIAL DA AQUICULTURA E DA PESCA '+
                  'PROTOCOLO DE RECEBIMENTO DO FORMULÁRIO DE SOLICITAÇÃO DA '+
                  'Licença de Pescador Profissional ',
            alignment: 'center',
            fontSize: 10,
            margin: [20, 0, 20, 0],
        },

        {
            margin: [20, 0, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:[300, 200],
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "01-NOME: ", bold: true, fontSize: 10}, 
                    {text: "", bold: true, fontSize: 10, rowSpan: 4, alignment: 'center', margin: [0, 45, 0, 0]}
                    ],
                    [{text: `${pessoa.nome}`, bold: true, fontSize: 10}, 
                    {text: "", bold: true, fontSize: 10, alignment: 'center', margin: [0, 45, 0, 0]}],
                    [{text: `02-CPF: `, bold: true, fontSize: 10}, 
                    {text: "", bold: true, fontSize: 10, alignment: 'center', margin: [0, 45, 0, 0]}],
                    [{text: `${pessoa.cpf}`, bold: true, fontSize: 10}, 
                    {text: "", bold: true, fontSize: 10, alignment: 'center', margin: [0, 45, 0, 0]}],
                
                
                ]
            }
        },

        {
            margin: [25, 5, 25, 0],
            fontSize: 8,
            text: [
                '* Este documento servirá unicamente como instrumento comprobatório da '+
                'entrega da documentação e, se deferido o pedido de inscrição, para comprovação '+
                'da data de 1º registro, nos termos do Parágrafo 1º do Incisos I, II e III do Art. '+
                '4º da Instrução Normativa nº6 /2012.',
            ]
        },

        



        

        
            
        
    ];

    function Rodape(currentPage, pageCount){
        // return[
        //     {
        //         text: "_______________________________________ \n Assinatura do Presidente ou Responsável pela Entidade",
        //         alignment: 'center',
        //         fontSize: 9,
        //         margin: [0, 0, 0, 0]
        //     }
        // ]
    }

    const docDefinitions = {
        pageSize: 'A4',
        // pageMargins: [15, 50, 15, 40],
        // pageMargins: [40, 80, 40, 70],
        pageMargins: [15, 80, 15, 30],

        header: {
            margin: [0, 10, 0, 20],
            columns: [
                {
                    image:ima,
                    margin: [50, 10, 0, 80],
                    width: 80,
                },
                {
                    text: 'MINISTÉRIO DA AGRICULTURA, PECUÁRIA E ABASTECIMENTO. ' + 
                            ' \nSECRETARIA DE AQUICULTURA E PESCA' +
                            '  \n REGISTRO GERAL DA ATIVIDADE PESQUEIRA ' +
                            ' \n FORMULÁRIO DE REQUERIMENTO DE LICENÇA DE PESCADOR PROFISSIONAL',
                    
                    fontSize: 10,
                    bold: true,
                    margin: [55, 20, 0, 30], //lef, top, right, bottom
                },
            ]
        },
        content: [details],
        footer: Rodape
    }

    pdfMake.createPdf(docDefinitions).open();

}

export default PescadorProfissional;