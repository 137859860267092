import { Card } from "@mui/material";
import * as S from "./style";

function MainPanel() {
  return (
    <S.MainPanelStyle>
      <Card className="panel">
        <img src="layte.png" alt="logo"/>
        <h2>Sistema de Cadastro de Pessoas</h2>
        <p>Versão 2.0</p>
      </Card>
    </S.MainPanelStyle>
  );
}

export default MainPanel;
