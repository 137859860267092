import ApiService from "./Api";

class UserService extends ApiService{
    constructor(){
        super("/user")
    }

    getAllUsers(){
        let url = `/getAll`;
        return this.getAll(url);
    }

}

export default UserService