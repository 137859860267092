import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import moment from 'moment'
import 'moment/locale/pt'
import imagem from "../../../Imagens/brasaoNacionaljpg.jpg";


function DeclaracaoPdf(pessoa){

    const ima = imagem;
    
    moment.locale('pt-br');
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    //const now = moment().format('LL');

    function convertData(data){
        var ano  = data.split("-")[0];
        var mes  = data.split("-")[1];
        var dia  = data.split("-")[2];
    
        return ("0"+dia).slice(-2) + '/' + ("0"+mes).slice(-2) + '/' + ano;
      }


    const reportTitle = [

        
        {

            image: ima,
            margin: [60, 20, 0, 0]
            
        },
        // {
        // text: 'MINISTÉRIO DA AGRICULTURA, PECUÁRIA E ABASTECIMENTO. ' + 
        //         ' \n SECRETARIA DE AQUICULTURA E PESCA.',
        
        // fontSize: 10,
        // bold: true,
        // margin: [200, 20, 0, 45], //lef, top, right, bottom
        // },

];

    const details = [

        {
            text: 'MINISTÉRIO DA AGRICULTURA, PECUÁRIA E ABASTECIMENTO. ' + 
                    ' \n SECRETARIA DE AQUICULTURA E PESCA.',
            
            fontSize: 10,
            bold: true,
            margin: [150, 0, 0, 0], //lef, top, right, bottom
        },


        {
            text: "DECLARAÇÃO DE FILIAÇÃO PARA PESCADOR OU PESCADORA PROFISSIONAL",
            alignment: 'center',
            fontSize: 10,
            bold: true,
            margin: [0, 40, 0, 0]
        },

        // {
        //     text: `Eu ${pessoa.nome}, CPF: ${pessoa.cpf} residente no ` +
        //           `Endereço ${pessoa.endereco}, DECLARO ser afiliado à Entidade abaixo especificada:`,
        //     fontSize: 10,
        //     bold: true,
        //     margin: [20, 40, 20, 0]
        // },
        {
            fontSize: 10, 
            bold: true,
            margin: [20, 40, 20, 0],
            text: [
                'Eu ',
                {text: `${pessoa.nome},`, fontSize: 10, bold: true, decoration: 'underline'},
                ' CPF: ',
                {text: `${pessoa.cpf}`, fontSize: 10, bold: true, decoration: 'underline'},
                ' residente no Endereço ',
                {text: `${pessoa.endereco}, Nº ${pessoa.numeroCasa === null ? "S/N" : pessoa.numeroCasa}, ${pessoa.bairro}, ${pessoa.municipio}, ${pessoa.estado}, cep: ${pessoa.cep}`, fontSize: 10, bold: true, decoration: 'underline'},
                ' DECLARO Ser filiado à Entidade abaixo especificada:'
            ]
        },

        {
            margin: [20, 40, 20, 0],
            //Como adicionar cores na tabela
            // layout: {
			// 	fillColor: '#CCCCCC'
			// 	},
            table: {
                widths:[500],
                body: [
                    // [{text: "Nome da Entidade: \nColonia de Pescadores z-53", fontSize: 10}],
                    [{text: "Nome da Entidade: \nColonia de Pescadores z-53", bold: true, fontSize: 10}],
                    [{text: "CNPJ da Entidade: \n02.154.454/0001-42", bold: true, fontSize: 10}],
                    [{text: "Nome Completo do Presidente ou Responsável pela Entidade: \nLUAN MARTINS COSTA", bold: true, fontSize: 10}],
                    [{text: "CPF do Presidente ou Responsável pela Entidade: \n060.988.883-88", bold: true, fontSize: 10}],
                    [{text: "Endereço da Entidade: \nAVENIDA PRINCIPAL Nº612, CENTRO, ", bold: true, fontSize: 10}],
                    [{text: "Municípo: \nRAPOSA", bold: true, fontSize: 10}],
                    [{text: "UF: \nMA", bold: true, fontSize: 10}],
                    [{text: `Data de Filiação: \n ${pessoa.dataFiliacao !== null ? convertData(pessoa.dataFiliacao) : ''}`, bold: true, fontSize: 10}],
                
                
                ]
            }
        },

        //data de hoje
        {
            text: `Raposa - MA, 01 de OUTUBRO de 2021.`,
            alignment: 'center',
            fontSize: 10,
            margin: [0, 30, 0, 0],

        },

        {
            text: '___________________________________________________________________', 
            alignment: 'center',
            fontSize: 10,
            margin: [0, 30, 0, 0],
        },

        {
            text: 'Assinatura do Pescador ou Pescadora Profissional',
            alignment: 'center',
            fontSize: 10,
            bold: true,
            margin: [0, 20, 0, 0],

        },

        {
            text: 'Declaro que o pescador ou pescadora acima especificado (a) é filiado ' +
             '(a) à Entidade em questão e exerce a atividade de pesca profissional.',
             fontSize: 10, 
             bold: true,
             margin: [20, 40, 20, 0],
        },

        {
            text: '___________________________________________________________________', 
            alignment: 'center',
            fontSize: 10,
            margin: [0, 30, 0, 0],
        },

        {
            text: 'Assinatura do Presidente ou Responsável pela Entidade',
            alignment: 'center',
            fontSize: 10,
            bold: true,
            margin: [0, 20, 0, 0],

        },
            
        
    ];

    function Rodape(currentPage, pageCount){
        // return[
        //     {
        //         text: "_______________________________________ \n Assinatura do Presidente ou Responsável pela Entidade",
        //         alignment: 'center',
        //         fontSize: 9,
        //         margin: [0, 0, 0, 0]
        //     }
        // ]
    }

    const docDefinitions = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 40],

        header: [reportTitle],
        content: [details],
        footer: Rodape
    }

    pdfMake.createPdf(docDefinitions).open();

}

export default DeclaracaoPdf;