import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../Services/authService";
import Card1 from "../Card";
import FormGroup from "../FormGroup";
import * as S from "./style";
//import BlockUI from "../BlockUI";
import { BlockUI } from 'primereact/blockui';
import { Toast } from "primereact/toast";


function FormularioLogin() {
  const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    /* flag para bloquear a página durante o carregamento */
    const [block, setBlock] = useState(false);

    
    const service = new AuthService();

    const toast = useRef(null);
    


    async function login(e){
        e.preventDefault();

        const data = {
            username,
            password,
        };

        try {
            setBlock(true);
            const response = await service.autenticar(data)
                            .finally(() => {
                                setBlock(false);
                            });

            // localStorage.setItem('username', username);
            // localStorage.setItem('accessToken', response.data.accessToken)

            // ApiService.registrarToken(response.data.accessToken);
            AuthService.logar(username, response.data.accessToken);
            
            navigate('/')
        } catch (err) {
            // alert('Email ou senha inválidos. \nRecaregue a página e tente novamente');
            toast.current.show({
              severity: "warn",
              summary: "Erro!",
              detail: "Email ou senha inválidos. \nRecaregue a página e tente novamente",
              life: 4000,
            });
        }
    };


  return (
    <S.FormularioLoginStyle>
      <form onSubmit={login}>
        <Toast ref={toast} />
        <div className="login-form">
        <BlockUI fullScreen blocked={block} template={<i className="pi pi-spin pi-spinner" style={{'fontSize': '5em'}} />}/>
          <Card1 title="Login">
            <fieldset>
              <FormGroup label="Login: *" htmlFor="exampleInputLogin">
                <input
                  type="text"
                  id="exampleInputLogin"
                  className="form-control"
                  arial-describedby="emailHelp"
                  placeholder="Digite seu login"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormGroup>

              <FormGroup label="Senha: *" htmlFor="exampleInputPassword1">
                <input
                  type="password"
                  id="exampleInputPassword1"
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>

              <button type="submit" className="btn btn-success">
                <i className="pi pi-sign-in" /> Entrar
              </button>

              {/* <Link to="/cadastro-usuarios">
                                                <button className="btn btn-danger">
                                                <i className="pi pi-plus"/> Cadastrar
                                                
                                                </button>
                                            </Link> */}
            </fieldset>
          </Card1>
        </div>
      </form>
    </S.FormularioLoginStyle>
  );

}

export default FormularioLogin;
