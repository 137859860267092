import styled from "styled-components";

export const FooterWrapper = styled.div`
  .footer {
    background-color: #e0e0e0;
    height: 3rem;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    
  }
`