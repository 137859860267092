import React, { useEffect } from "react";
import Rotas from "./Components/Rotas";

import "bootswatch/dist/flatly/bootstrap.css";

import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import { useState } from "react";
import AuthService from "./Services/authService";
import jwtDecode from "jwt-decode";

const FiltrosContext = React.createContext();
const AuthContext = React.createContext();

function App() {
  const [filtros, setFiltros] = useState({
    nome: "",
    cpf: "",
    dataInicio: null,
    dataFim: null,
    userId: null
  });

  const [authState, setAuthState] = useState({
    usuarioAutenticado: null,
    isAutenticado: false,
    isLoading: true,
  });

  const iniciarSessao = (tokenDTO) => {
    const token = tokenDTO.token;
    const claims = jwtDecode(token);
    const usuario = {
      id: claims.userId,
      nome: claims.nome,
    };

    AuthService.logar(usuario, token);
    setAuthState({
      ...authState,
      isAutenticado: true,
      usuarioAutenticado: usuario,
    });
  };

  const encerrarSessao = () => {
    AuthService.removerUsuarioAutenticado();
    setAuthState({
      ...authState,
      isAutenticado: false,
      usuarioAutenticado: null,
    });
  };
  
  const [authContext, setAuthContext] = useState({
    ...authState,
    iniciarSessao,
    encerrarSessao,
  });

  useEffect(() => {
    const isAutenticado = AuthService.isUsuarioAutenticado();

    if (isAutenticado) {
      const usuario = AuthService.refreshSession();
      setAuthState({
        usuarioAutenticado: usuario,
        isAutenticado: true,
        isLoading: false,
      });
    }
  }, [authContext, filtros]);


  return (
    <AuthContext.Provider value={authContext}>
      <FiltrosContext.Provider value={[filtros, setFiltros]}>
        <Rotas />
      </FiltrosContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
export { FiltrosContext, AuthContext };
