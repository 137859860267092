import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiltrosContext } from "../../App";
import Card1 from "../Card";
import PessoaService from "../../Services/pessoaService";
import { Dropdown } from 'primereact/dropdown';
import FormGroup from "../FormGroup";

//import de documentos
import DeclaracaoPdf from "../Reports/Filiacao";
import ResidenciaPdf from "../Reports/Residencia";
import PescadorProfissional from "../Reports/PescadorProfissional";

import * as S from "./style";
import { useEffect } from "react";

// import BlockUI from "../BlockUI";
import { BlockUI } from "primereact/blockui";
import UserService from "../../Services/userService";
import jwtDecode from "jwt-decode";

function FormBusca(props) {
  /*
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  */
  const [pessoasListBuscar, setPessoasListBuscar] = useState([]);
  const toast = useRef(null);
  const menu = useRef(null);
  const navigate = useNavigate();
  const [pessoaSelecionada, setPessoaSelecionada] = useState(null);
  /* flag para bloquear a página durante o carregamento */
  const [block, setBlock] = useState(false);

  const [pessoasCount, setPessoasCount] = useState(0);
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
  });
  const [filtros, setFiltros] = useContext(FiltrosContext);
  const [users, setUsers] = useState();
  const [selectedUser, setSelectedUser] = useState();


  const [usersMap, setUserMap] = useState([]);
  const [acesso, setAcesso] = useState();

  let dataInicioFormatada = null;
  let dataFimFormatada = null;

  const service = new PessoaService();
  const userService = new UserService();

  useEffect(() => {
    if (props.edit) {
      toast.current.show({
        severity: "info",
        summary: "Confirmado",
        detail: "Dados atualizados com sucesso.",
        life: 3000,
      });
    }

    if (props.paginaAtual != null) {
      buscar(props.paginaAtual);
    }
  }, []);
  
  useEffect(() => {
    getUsers();
    
  }, []);
  
  useEffect(() => {
    if(users){
      const lista = Object.keys(users).map(chave => {
        
        return {name: users[chave], code: chave.toString()};
        
      });
      
      setUserMap(lista);
      const token = jwtDecode(localStorage.getItem("accessToken"));
      setAcesso(token.roles[0]);
    }

  }, [users]);



  const getUsers = async () => {
    const response = await userService.getAllUsers();
    setUsers(response.data);
  }

  //Funções para paginação
  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
    buscar(newPage);
  };

  //Funções para paginação
  /*const handlePageChangeVoltar = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };*/

  //Funções para paginação
  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  //Verifica os dados e depois chama a função de buscar
  const verificarDados = () => {
    if (filtros.dataInicio !== null) {
      let dataInicioFormatada2 =
        filtros.dataInicio.getFullYear() +
        "-" +
        (filtros.dataInicio.getMonth() < 9
          ? "0" + (filtros.dataInicio.getMonth() + 1)
          : filtros.dataInicio.getMonth() + 1) +
        "-" +
        (filtros.dataInicio.getDate() < 10
          ? "0" + filtros.dataInicio.getDate()
          : filtros.dataInicio.getDate());
      dataInicioFormatada = dataInicioFormatada2;
    }

    if (filtros.dataFim !== null) {
      let dataFimFormatada2 =
        filtros.dataFim.getFullYear() +
        "-" +
        (filtros.dataFim.getMonth() < 9
          ? "0" + (filtros.dataFim.getMonth() + 1)
          : filtros.dataFim.getMonth() + 1) +
        "-" +
        (filtros.dataFim.getDate() < 10
          ? "0" + filtros.dataFim.getDate()
          : filtros.dataFim.getDate());

      dataFimFormatada = dataFimFormatada2;
    }

    return {
      data: {
        dataInicioFormatada,
        dataFimFormatada,
      },
    };
  };

  const items = [
    {
      label: "Emitir declaração de",
      items: [
        {
          label: "Filiação",
          icon: "pi pi-file-pdf",
          command: () => {
            DeclaracaoPdf(pessoaSelecionada);
          },
        },
        {
          label: "Residência",
          icon: "pi pi-file-pdf",
          command: () => {
            ResidenciaPdf(pessoaSelecionada);
          },
        },
        {
          label: "FLPP",
          icon: "pi pi-file-pdf",
          command: () => {
            setBlock(true);
            // Relatório antigo
            // PescadorProfissional(pessoaSelecionada);
            service
              .gerarRelatorioFlpp(pessoaSelecionada)
              .then((response) => {
                const blob = new Blob([response.data], {
                  type: "application/pdf",
                });

                const fileURL = URL.createObjectURL(blob);

                window.open(fileURL);
              })
              .catch((error) => {
                console.log("erro", error);
              })
              .finally(() => {
                setBlock(false);
              });
          },
        },
        {
          label: "SDPA",
          icon: "pi pi-file-pdf",
          command: () => {
            setBlock(true);
            service
              .gerarRelatorio(pessoaSelecionada)
              .then((response) => {
                const blob = new Blob([response.data], {
                  type: "application/pdf",
                });

                const fileURL = URL.createObjectURL(blob);

                window.open(fileURL);
              })
              .catch((error) => {
                console.log("erro", error);
              })
              .finally(() => {
                setBlock(false);
              });
          },
        },
        {
          label: "GPS",
          icon: "pi pi-file-pdf",
          command: () => {
            setBlock(true);
            service
              .gerarRelatorioGps(pessoaSelecionada)
              .then((response) => {
                const blob = new Blob([response.data], {
                  type: "application/pdf",
                });

                const fileURL = URL.createObjectURL(blob);

                window.open(fileURL);
              })
              .catch((error) => {
                console.log("erro", error);
              })
              .finally(() => {
                setBlock(false);
              });
          },
        },
        {
          label: "Seguro Especial",
          icon: "pi pi-file-pdf",
          command: () => {
            setBlock(true);
            service
              .gerarRelatorioSeguro(pessoaSelecionada)
              .then((response) => {
                console.log(response);

                const blob = new Blob([response.data], {
                  type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });

                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = `seguro-especial-${pessoaSelecionada.nome}.docx`;
                link.click();
              })
              .catch((error) => {
                console.log("erro", error);
              })
              .finally(() => {
                setBlock(false);
              });
          },
        },
      ],
    },
  ];

  function abrirMenu(event, pessoa) {
    menu.current.toggle(event);
    setPessoaSelecionada(pessoa);
  }

  addLocale("pt", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "segunda",
      "terça",
      "quarta",
      "quinta",
      "sexta",
      "sábado",
    ],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "jan",
      "fev",
      "mar",
      "abr",
      "mai",
      "jun",
      "jul",
      "ago",
      "set",
      "out",
      "nov",
      "dez",
    ],
    today: "Hoje",
    clear: "Limpar",
  });

  function convertData(data) {
    var ano = data.split("-")[0];
    var mes = data.split("-")[1];
    var dia = data.split("-")[2];

    return ("0" + dia).slice(-2) + "/" + ("0" + mes).slice(-2) + "/" + ano;
  }

  async function buscar(e) {
    setBlock(true);
    let pagina = 0;
    if (isNaN(e) === true) {
      e.preventDefault();
    } else {
      pagina = e;
    }

    const datasFormatadas = verificarDados();
    let dataInicioFormatada = datasFormatadas.data.dataInicioFormatada;
    let dataFimFormatada = datasFormatadas.data.dataFimFormatada;

    const dadosBusca = {
      nome: filtros.nome,
      cpf: filtros.cpf,
      dataInicioFormatada,
      dataFimFormatada,
      pagina,
      userId: selectedUser?.code
    };

    await service
      .buscar(dadosBusca)
      .then((response) => {
        setPessoasListBuscar(response.data._embedded.pessoaDTOList);
        setPessoasCount(response.data.page.totalElements);
        setController({
          ...controller,
          rowsPerPage: parseInt(10),
          page: pagina,
        });
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: "Nenhum resultado encontrado para os parâmetros informados.",
          life: 3000,
        });
      })
      .finally(() => {
        setBlock(false);
      });
  }

  function limpar() {
    setFiltros({
      nome: "",
      cpf: "",
      dataInicio: null,
      dataFim: null,
      userId: null,
    });
    setSelectedUser(null);
    setPessoasListBuscar([]);
    setPessoasCount(0);
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
    });
  }

  function gerarRelatorioGeral(){
    setBlock(true);
    service
          .gerarRelatorioGeral()
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "application/pdf",
            });

            const fileURL = URL.createObjectURL(blob);

            window.open(fileURL);
          })
          .catch((error) => {
            console.log("erro", error);
          })
          .finally(() => {
            setBlock(false);
          });
  }

  //Redireciona para a página de detalhe passando o id do processo
  //para a buscar os movimentos.
  function handleRedirectSearch(idPessoa) {
    //Função pra redirecionar pra página de busca com o campo de NumProcesso preenchido

    navigate("/detalhar-pessoas", {
      state: { idPessoa: idPessoa, paginaAtual: controller.page },
    });
  }

  return (
    <S.FormBuscaStyle>
      <form onSubmit={buscar}>
        <Toast ref={toast} />
        <BlockUI
          fullScreen
          blocked={block}
          template={
            <i className="pi pi-spin pi-spinner" style={{ fontSize: "5em" }} />
          }
        />
        <Card1 title="Consultar Pessoas">
          <Toast ref={toast} />
          <div className="bs-component">
            <FormGroup
              label="Nome: *"
              htmlFor="inputNome"
              className="form-field"
            >
              <input
                type="text"
                className="form-control"
                id="inputNome"
                placeholder="Digite o nome..."
                value={filtros.nome}
                onChange={(e) =>
                  setFiltros({ ...filtros, nome: e.target.value })
                }
              />
            </FormGroup>

            <FormGroup label="CPF: " htmlFor="inputCpf" className="form-field">
              <input
                type="text"
                className="form-control"
                id="inputCpf"
                placeholder="Digite o cpf..."
                value={filtros.cpf}
                onChange={(e) =>
                  setFiltros({ ...filtros, cpf: e.target.value })
                }
              />
            </FormGroup>
            <div className="row">
              <div className="col-sm-6">
                <FormGroup
                  label="Data inicial: "
                  htmlFor="dataInicio"
                  className="form-field"
                >
                  <div className="p-fluid grid formgrid">
                    <Calendar
                      minDate={filtros.dataInicio}
                      locale="pt"
                      dateFormat="dd/mm/yy"
                      className="form-input"
                      id="dataInicio"
                      placeholder="Selecione uma data de início..."
                      value={filtros.dataInicio}
                      onChange={(e) =>
                        setFiltros({ ...filtros, dataInicio: e.target.value })
                      }
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-sm-6">
                <FormGroup
                  label="Data final: "
                  htmlFor="dataFim"
                  className="form-field"
                >
                  <div className="p-fluid grid formgrid">
                    <Calendar
                      minDate={filtros.dataFim}
                      locale="pt"
                      dateFormat="dd/mm/yy"
                      className="form-input"
                      id="dataFim"
                      placeholder="Selecione uma data de início..."
                      value={filtros.dataFim}
                      onChange={(e) =>
                        setFiltros({ ...filtros, dataFim: e.target.value })
                      }
                    />
                  </div>
                </FormGroup>
              </div>
            </div>
            {acesso === "ADMIN" && (<div className="row">
              <div className="col-sm-6">
                <FormGroup
                  label="Usuários: "
                  htmlFor="usuarios"
                  className="form-field"
                >
                  <div className="p-fluid grid formgrid">
                  <Dropdown id="usuarios" value={selectedUser} onChange={(e) => setSelectedUser(e.value)} options={usersMap} optionLabel="name" 
                      placeholder="Usuários" className="w-full md:w-14rem" showClear/>
                  </div>
                </FormGroup>
              </div>
            </div>)} 

            <button onClick={buscar} type="submit" className="btn btn-success">
              <i className="pi pi-search" /> Buscar
            </button>

            <button onClick={limpar} type="button" className="btn btn-danger">
              <i className="pi pi-times" /> limpar
            </button>

            {acesso === "ADMIN" && (<button onClick={gerarRelatorioGeral} type="button" className="btn btn-warning">
              <i className="pi pi-file-pdf" /> Imprimir todos os cadastros
            </button>)}
          </div>

          {pessoasListBuscar.length > 0 && (
            <div className="form-container">
              <Card elevation={2}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>RG</TableCell>
                      <TableCell>CPF</TableCell>
                      <TableCell>Data de nascimento</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pessoasListBuscar.map((pessoa) => (
                      <TableRow key={pessoa.id}>
                        <TableCell>{pessoa.nome}</TableCell>
                        <TableCell>{pessoa.rg}</TableCell>
                        <TableCell>{pessoa.cpf}</TableCell>
                        <TableCell>
                          {convertData(pessoa.dataNascimento)}
                        </TableCell>
                        <TableCell>
                          <button
                            onClick={() => handleRedirectSearch(pessoa.id)}
                            type="button"
                            className="btn btn-success btn-table"
                            title="Detalhar"
                          >
                            <i className="pi pi-search" />
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  onPageChange={handlePageChange}
                  page={controller.page}
                  count={pessoasCount}
                  rowsPerPage={controller.rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </div>
          )}

          <br />
          <div className="row">
            <div className="col-md-12">
              <div className="bs-component"></div>
            </div>
          </div>
        </Card1>
      </form>
    </S.FormBuscaStyle>
  );
}

export default FormBusca;
