import React from "react";
import TelaInicial from "../../Components/TelaInicial";

function Home(){
    return(
        <>
            <TelaInicial />
        </>
    )
}

export default Home;