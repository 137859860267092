import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import FormularioPessoa from "../../Components/FormularioPessoa";
import AuthService from "../../Services/authService";
import * as S from "./style"; //Importando conteúdo do styled component

function EditarPessoas() {

  const location = useLocation();
  let idPessoa = false;
  let paginaAtual = null;
  let modoEdicao = true;
  if (location.state != null) {
    idPessoa = location.state.idPessoa;
    paginaAtual = location.state.paginaAtual;
    modoEdicao = location.state.modoEdicao;
  }

  if (AuthService.isUsuarioAutenticado()) {
    return (
      <S.CadastroPessoasWrapper>
        <FormularioPessoa
          idPessoa={idPessoa}
          paginaAtual={paginaAtual}
          modoEdicao={modoEdicao}
        />
      </S.CadastroPessoasWrapper>
    );
  }
  return <Navigate to={"/login"} />;
}

export default EditarPessoas;
