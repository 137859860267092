import { addLocale } from "primereact/api";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from 'primereact/inputtextarea';
import { Image } from "primereact/image";
import { InputMask } from "primereact/inputmask";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import FileService from "../../Services/fileService";
import PessoaService from "../../Services/pessoaService";
import Card1 from "../Card";
import FormGroup from "../FormGroup";
import * as S from "./style";
//import BlockUI from "../BlockUI";
import { BlockUI } from "primereact/blockui";
import { Checkbox } from "primereact/checkbox";

function FormularioPessoa(props) {
  const navigate = useNavigate();

  const [modoEdicao, setModoEdicao] = useState();
  const [nome, setNome] = useState();
  const [rg, setRg] = useState();
  const [cpf, setCpf] = useState();
  const [dataNascimentoOriginal, setDataNascimentoOriginal] = useState();
  const [nomeMae, setNomeMae] = useState();
  const [nomePai, setNomePai] = useState();
  const [estadoCivil, setEstadoCivil] = useState(null);
  const [profissao, setProfissao] = useState();
  const [endereco, setEndereco] = useState();
  const [numeroCasa, setNumeroCasa] = useState();
  const [bairro, setBairro] = useState();
  const [municipio, setMunicipio] = useState();
  const [estado, setEstado] = useState("MA");
  const [cep, setCep] = useState();
  const [email, setEmail] = useState();
  const [genero, setGenero] = useState();
  const [dataEmissaoCpf, setDataEmissaoCpf] = useState();
  const [celular, setCelular] = useState();
  const [numeroTitulo, setNumeroTitulo] = useState();
  const [numeroNit, setNumeroNit] = useState();
  const [numeroCei, setNumeroCei] = useState();
  const [numeroPis, setNumeroPis] = useState();
  const [fotoPerfil, setFotoPerfil] = useState();
  const [fotoPerfilAtual, setFotoPerfilAtual] = useState();
  const [file, setFile] = useState(new FormData());
  const [uploadFeito, setUpoadlFeito] = useState(false);
  const [id, setId] = useState();
  const [imagemPerfil, setImagemPerfil] = useState();
  const [senhaInssGov, setSenhaInssGov] = useState();
  const [dataFiliacaoSemFormato, setDataFiliacaoSemFormato] = useState();
  const [naturalidade, setNaturalidade] = useState();
  const [observacao, setObservacao] = useState();
  const [sisrgp40, setSisrgp40] = useState();
  /* flag para bloquear a página durante o carregamento */
  const [block, setBlock] = useState(false);

  const [loading, setLoading] = useState(true);
  const [pessoa, setPessoa] = useState({});

  const [semNumero, setSemNumero] = useState(false);

  useEffect(() => {
    const buscarPessoa = async () => {
      service
        .findById(props.idPessoa)
        .then((response) => {
          setLoading(false);
          setPessoa(response.data);
          setId(response.data.id);
          setNome(response.data.nome);
          setCelular(response.data.celular);
          setNumeroCasa(response.data.numeroCasa);
          setCpf(response.data.cpf);
          setRg(response.data.rg);
          setGenero("" + response.data.genero);
          setEmail(response.data.email);
          setProfissao(response.data.profissao);
          setBairro(response.data.bairro);
          setMunicipio(response.data.municipio);
          setEstado(response.data.estado);
          setCep(response.data.cep);
          setSenhaInssGov(response.data.senhaInssGov);
          setDataEmissaoCpf(convertEditar(response.data.dataEmissao));
          setDataNascimentoOriginal(
            convertEditar(response.data.dataNascimento)
          );
          if (response.data.dataFiliacao) {
            setDataFiliacaoSemFormato(
              convertEditar(response.data.dataFiliacao)
            );
          }
          setNomeMae(response.data.nomeMae);
          setNomePai(response.data.nomePai);
          setEndereco(response.data.endereco);
          setEstadoCivil(response.data.estadoCivil);
          setNumeroTitulo(response.data.numeroTitulo);
          setNumeroNit(response.data.numeroNit);
          setNumeroCei(response.data.numeroCei);
          setNumeroPis(response.data.numeroPis);
          setFotoPerfil(response.data.fotoPerfil);
          setFotoPerfilAtual(response.data.fotoPerfil);
          setNaturalidade(response.data.naturalidade);
          setObservacao(response.data.observacao);
          setSisrgp40(response.data.sisrgp40);
          setModoEdicao(true);
          if (response.data.fotoPerfil) {
            buscarPerfil(response.data.fotoPerfil);
          }
          if (response.data.numeroCasa === null){
            setSemNumero(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (props.idPessoa && loading === true && !props.fromNavbar) {
      buscarPessoa();
    }
  }, [pessoa]);

  const toast = useRef(null);

  let fileUploadRef = null;

  const estadoCivilOptions = [
    { name: "Solteiro(a)", code: "1" },
    { name: "Casado(a)", code: "2" },
    { name: "Divorciado(a)", code: "3" },
    { name: "União Estável", code: "4" },
    { name: "Viúvo(a)", code: "5" },
  ];

  const generoOptions = [
    { name: "Masculino", code: "1" },
    { name: "Feminino", code: "2" },
    { name: "Outro", code: "3" },
  ];

  const service = new PessoaService();
  const fileService = new FileService();

  const limpar = () => {
    setNome("");
    setRg("");
    setCpf();
    setDataNascimentoOriginal();
    setNomeMae("");
    setNomePai("");
    setProfissao("");
    setEndereco("");
    setNumeroCasa("");
    setBairro("");
    setMunicipio("");
    setEstado("MA");
    setCep("");
    setEmail("");
    setGenero();
    setEstadoCivil();
    setCelular();
    setDataEmissaoCpf();
    setNumeroTitulo("");
    setNumeroNit("");
    setNumeroCei("");
    setNumeroPis("");
    setFotoPerfil("");
    setDataFiliacaoSemFormato();
    setSenhaInssGov("");
    setSemNumero(false);
    setNaturalidade("");
    setObservacao("");
    setSisrgp40("");
  };

  // const entrar = () =>{
  //     Api
  //     .get("/pessoas/36")
  //     .then((response) => {
  //         console.log(response)
  //     })
  //     .catch((erro) => {
  //       console.log(erro);
  //     });

  addLocale("pt", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "segunda",
      "terça",
      "quarta",
      "quinta",
      "sexta",
      "sábado",
    ],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "jan",
      "fev",
      "mar",
      "abr",
      "mai",
      "jun",
      "jul",
      "ago",
      "set",
      "out",
      "nov",
      "dez",
    ],
    today: "Hoje",
    clear: "Limpar",
  });

  async function buscarPerfil(nome) {
    await fileService.buscarPerfil(nome).then((response) => {
      var base64Flag = "data:image/jpeg;base64,";
      setImagemPerfil(base64Flag + response.data);
    });
  }

  function deleteImagemPerfil() {
    setImagemPerfil(null);
    setFotoPerfil(null);
    toast.current.show({
      severity: "info",
      summary: "Confirmado",
      detail: "Imagem do perfil excluído",
      life: 3000,
    });
  }
  async function salvar(e) {
    setBlock(true);
    e.preventDefault();

    let dataEmissao = null;
    let dataNascimento = null;

    if (dataEmissaoCpf) {
      if (!dataEmissaoCpf.includes("_")) {
        dataEmissao = convertData(dataEmissaoCpf);
      }
    }

    if (dataNascimentoOriginal) {
      if (!dataNascimentoOriginal.includes("_")) {
        dataNascimento = convertData(dataNascimentoOriginal);
      }
    }

    let dataFiliacao;

    if (dataFiliacaoSemFormato) {
      if (!dataFiliacaoSemFormato.includes("_")) {
        dataFiliacao = convertData(dataFiliacaoSemFormato);
      }
    }

    const data = {
      nome,
      rg,
      dataEmissao,
      cpf,
      dataNascimento,
      genero,
      nomeMae,
      nomePai,
      estadoCivil,
      profissao,
      endereco,
      numeroCasa,
      bairro,
      estado,
      municipio,
      cep,
      email,
      celular,
      numeroTitulo,
      dataFiliacao,
      senhaInssGov,
      numeroNit,
      numeroCei,
      numeroPis,
      fotoPerfil,
      naturalidade,
      observacao,
      sisrgp40,
    };

    data.id = id;
    await service
      .salvar(data)
      .then((response) => {
        if (fotoPerfil !== fotoPerfilAtual) {
          fileService.deleteImagem(fotoPerfilAtual);
        }
        if (file) {
          fileService
            .salvar(file)
            .then(() => {})
            .catch((error) => {});
        }

        toast.current.show({
          severity: "info",
          summary: "Sucesso!",
          detail: "Registro salvo com sucesso.",
          life: 3000,
        });

        limpar();
        setFile(new FormData());
        setUpoadlFeito(false);
        if (modoEdicao) {
          navigate("/detalhar-pessoas", {
            state: { idPessoa: data.id, paginaAtual: props.paginaAtual, edit: modoEdicao },
          });
        }
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: error.response.data,
          life: 5000,
        });
      })
      .finally(() => {
        setBlock(false);
      });
  }

  const onUploadImagemPerfil = ({ files }) => {
    setFotoPerfil(`${files[0].size}-${files[0].name}`);
    // console.log(files[0])
    // console.log(files[0].name);
    // const nome = sha512(files[0]);
    // console.log(nome);

    // (async () => {
    //   const buf = await files[0].arrayBuffer();
    //   console.log( buf.byteLength ); // 5
    // })();

    // file.append("file", files[0]);
    files.forEach((arquivo, index) => {
      file.append(`files`, arquivo);
    });

    fileUploadRef.clear();

    toast.current.show({
      severity: "info",
      summary: "Sucesso!",
      detail: "Foto anexada com sucesso.",
      life: 3000,
    });

    setUpoadlFeito(true);

    // fileService.salvar(file)
    //             .then(response => {
    //               console.log(response)
    //             }).catch(error => {
    //               console.log(error)
    //             });
  };

  const chooseOptions = { label: "Selecione", icon: "pi pi-fw pi-plus" };
  const uploadOptions = {
    label: "Confirmar",
    icon: "pi pi-upload",
    className: "p-button-success",
  };
  const cancelOptions = {
    label: "Cancelar",
    icon: "pi pi-times",
    className: "p-button-danger",
  };

  const emptyTemplate = () => {
    if (uploadFeito === false) {
      return <p className="m-0">Arraste ou selecione uma foto.</p>;
    }

    return <p className="m-0">Foto selecionada com sucesso.</p>;
  };

  function convertData(data) {
    var dia = data.split("-")[0];
    var mes = data.split("-")[1];
    var ano = data.split("-")[2];

    return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2);
  }

  function convertEditar(data) {
    var ano = data.split("-")[0];
    var mes = data.split("-")[1];
    var dia = data.split("-")[2];

    return ("0" + dia).slice(-2) + "-" + ("0" + mes).slice(-2) + "-" + ano;
  }

  function verificaNumero(semNumero){
    if(semNumero){
      setNumeroCasa("");
    }
    setSemNumero(semNumero);
  }

  function goCancelar(){
    if(props.modoEdicao){
      navigate("/detalhar-pessoas", {
        state: { idPessoa: props.idPessoa, paginaAtual: props.paginaAtual },
      });
    }
    else{
      navigate("/");
    }

    
  }

  return (
    <S.FormularioPessoaStyle>
      <form onSubmit={salvar}>
        <BlockUI
          fullScreen
          blocked={block}
          template={
            <i className="pi pi-spin pi-spinner" style={{ fontSize: "5em" }} />
          }
        />
        <Card1 title={modoEdicao ? "Editar Pessoa" : "Cadastro de Pessoas"}>
          <Toast ref={toast} />
          <div className="row">
            {/*método que será chamado sempre que o valor de redirect for mudado
                      o navigate séra chamado e encaminhado para a rota passada. */}
            {/* {this.state.redirect && <Navigate to="/login" replace={true} />} */}
            <div className="">
              <div className="bs-component">
                <div className="row">
                  <div className="col-sm-8">
                    <FormGroup label="Nome: *" htmlFor="inputNome">
                      <input
                        type="text"
                        id="inputNome"
                        name="nome"
                        className="form-control"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-4">
                    <FormGroup
                      label="CPF: *"
                      htmlFor="inputCpf"
                      className="input-cpf"
                    >
                      {/* <input type="text" id="inputCpf"
                                      name="cpf" className="form-control"
                                      value={cpf}
                                    onChange={e => setCpf(e.target.value)} /> */}
                      <InputMask
                        className="form-control"
                        id="inputCpf"
                        mask="999.999.999-99"
                        value={cpf}
                        placeholder="999.999.999-99"
                        autoClear={false}
                        onChange={(e) => setCpf(e.value)}
                      ></InputMask>
                    </FormGroup>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <FormGroup label="RG: " htmlFor="inputRg">
                      <input
                        type="text"
                        id="inputRg"
                        name="Rg"
                        className="form-control"
                        value={rg}
                        onChange={(e) => setRg(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-2">
                    <FormGroup
                      label="Data de Emissão: "
                      htmlFor="dataEmissao"
                      className="form-field"
                    >
                      <div className="p-fluid grid formgrid">
                        <InputMask
                          className="form-control"
                          id="dataEmissao"
                          mask="99-99-9999"
                          value={dataEmissaoCpf}
                          placeholder="99/99/9999"
                          autoClear={false}
                          onChange={(e) => setDataEmissaoCpf(e.target.value)}
                        ></InputMask>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-sm-3">
                    <FormGroup
                      label="Data de Nascimento: *"
                      htmlFor="inputDataNascimento"
                    >
                      <InputMask
                        className="form-control"
                        id="inputDataNascimento"
                        mask="99-99-9999"
                        value={dataNascimentoOriginal}
                        placeholder="99/99/9999"
                        autoClear={false}
                        onChange={(e) => setDataNascimentoOriginal(e.value)}
                      ></InputMask>
                    </FormGroup>
                  </div>

                  <div className="col-sm-3">
                    <FormGroup label="Gênero: *" htmlFor="inputGenero">
                      <Dropdown
                        className="form-control"
                        id="inputGenero"
                        value={genero}
                        options={generoOptions}
                        onChange={(e) => setGenero(e.value)}
                        placeholder="Selecione o Gênero"
                        optionLabel="name"
                        optionValue="code"
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <FormGroup label="Nome da Mãe: *" htmlFor="inputNomeMae">
                      <input
                        type="text"
                        id="inputNomeMae"
                        name="nomeMae"
                        className="form-control"
                        value={nomeMae}
                        onChange={(e) => setNomeMae(e.target.value)}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-sm-6">
                    <FormGroup label="Nome do Pai: *" htmlFor="inputNomePai">
                      <input
                        type="text"
                        id="inputNomePai"
                        name="nomePai"
                        className="form-control"
                        value={nomePai}
                        onChange={(e) => setNomePai(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-3">
                    <FormGroup
                      label="Estado civil: *"
                      htmlFor="inputEstadoCivil"
                    >
                      <Dropdown
                        className="form-control"
                        id="inputEstadoCivil"
                        value={estadoCivil}
                        options={estadoCivilOptions}
                        onChange={(e) => setEstadoCivil(e.value)}
                        placeholder="Selecione o estado civil"
                        optionLabel="name"
                        optionValue="code"
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-2">
                    <FormGroup label="Celular: *" htmlFor="inputCelular">
                      {/* <input type="text" id="inputDataNascimento"
                                      name="dataNAscimento" className="form-control"
                                      value={dataNascimento}
                                      onChange={e => setDataNascimento(e.target.value)} /> */}
                      <InputMask
                        className="form-control"
                        id="inputCelular"
                        mask="(99) 99999-9999"
                        value={celular}
                        autoClear={false}
                        placeholder="(99) 99999-9999"
                        onChange={(e) => setCelular(e.value)}
                      ></InputMask>
                    </FormGroup>
                  </div>

                  <div className="col-sm-4">
                    <FormGroup label="Email: *" htmlFor="inputEmail">
                      <input
                        type="text"
                        id="inputEmail"
                        name="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-sm-3">
                    <FormGroup label="Profissão: *" htmlFor="inputProfissao">
                      <input
                        type="text"
                        id="inputProfissao"
                        name="profissao"
                        className="form-control"
                        value={profissao}
                        onChange={(e) => setProfissao(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row"></div>
                <div className="row">
                  <div className="col-sm-6">
                    <FormGroup label="Endereço: *" htmlFor="inputEndereco">
                      <input
                        type="text"
                        id="inputEndereco"
                        name="endereco"
                        className="form-control"
                        value={endereco}
                        onChange={(e) => setEndereco(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-2">
                    <FormGroup label="Número: *" htmlFor="inputNumero">
                      <input
                        type="number"
                        id="inputNumero"
                        name="numero"
                        className="form-control"
                        disabled={semNumero}
                        value={numeroCasa}
                        onChange={(e) => setNumeroCasa(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-2 checkbox-form">
                    <FormGroup label="Sem número: *" htmlFor="inputNumero" >
                      <Checkbox
                        inputId="binary"
                        id="inputNumero"
                        checked={semNumero}
                        onChange={(e) => verificaNumero(e.checked)}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-2">
                    <FormGroup label="Bairro: *" htmlFor="inputBairro">
                      <input
                        type="text"
                        id="inputBairro"
                        name="bairro"
                        className="form-control"
                        value={bairro}
                        onChange={(e) => setBairro(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <FormGroup label="Município: *" htmlFor="inputMunicipio">
                      <input
                        type="text"
                        id="inputMunicipio"
                        name="municipio"
                        className="form-control"
                        value={municipio}
                        onChange={(e) => setMunicipio(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-4">
                    <FormGroup label="Estado: *" htmlFor="inputEstado">
                      <input
                        type="text"
                        id="inputEstado"
                        name="estado"
                        className="form-control"
                        value={estado}
                        onChange={(e) => setEstado(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-4">
                    <FormGroup label="CEP: *" htmlFor="inputCep">
                      <input
                        type="text"
                        id="inputCep"
                        name="cep"
                        className="form-control"
                        value={cep}
                        onChange={(e) => setCep(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <FormGroup label="Senha Inss/Gov: " htmlFor="inputSenha">
                      <input
                        type="text"
                        id="inputSenha"
                        name="senhaInssGov"
                        className="form-control"
                        value={senhaInssGov}
                        onChange={(e) => setSenhaInssGov(e.target.value)}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-sm-4">
                    <FormGroup
                      label="Número do título: "
                      htmlFor="inputNumeroTitulo"
                    >
                      <input
                        type="text"
                        id="inputNumeroTitulo"
                        name="numeroTitulo"
                        className="form-control"
                        value={numeroTitulo}
                        onChange={(e) => setNumeroTitulo(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-4">
                    <FormGroup label="Número do NIT: " htmlFor="inputNumeroNit">
                      <input
                        type="text"
                        id="inputNumeroNit"
                        name="numeroNit"
                        className="form-control"
                        value={numeroNit}
                        onChange={(e) => setNumeroNit(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-6">
                    <FormGroup label="Número do CEI: " htmlFor="inputNumeroCei">
                      <input
                        type="text"
                        id="inputNumeroCei"
                        name="numeroCei"
                        className="form-control"
                        value={numeroCei}
                        onChange={(e) => setNumeroCei(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-4">
                    <FormGroup label="Número do PIS: " htmlFor="inputNumeroPis">
                      <input
                        type="text"
                        id="inputNumeroPis"
                        name="numeroPis"
                        className="form-control"
                        value={numeroPis}
                        onChange={(e) => setNumeroPis(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-2">
                    <FormGroup
                      label="Data de Filiação: "
                      htmlFor="dataFiliacao"
                      className="form-field"
                    >
                      <div className="p-fluid grid formgrid">
                        <InputMask
                          className="form-control"
                          id="dataFiliacao"
                          mask="99-99-9999"
                          value={dataFiliacaoSemFormato}
                          placeholder="99/99/9999"
                          autoClear={false}
                          onChange={(e) => setDataFiliacaoSemFormato(e.value)}
                        ></InputMask>
                      </div>
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                      <FormGroup
                        label="Naturalidade: "
                        htmlFor="naturalidade"
                        className="form-field"
                      >
                        <input
                          type="text"
                          id="naturalidade"
                          name="naturalidade"
                          className="form-control"
                          value={naturalidade}
                          onChange={(e) => setNaturalidade(e.target.value)}
                        />
                      </FormGroup>
                    </div>

                    <div className="col-sm-6">
                      <FormGroup
                        label="SISRGP4.0: "
                        htmlFor="sisrgp40"
                        className="form-field"
                      >
                        <input
                          type="text"
                          id="sisrgp40"
                          name="sisrgp40"
                          className="form-control"
                          value={sisrgp40}
                          onChange={(e) => setSisrgp40(e.target.value)}
                        />
                      </FormGroup>
                    </div>

                    <div className="col-sm-12">
                      <FormGroup
                        label="Observação: "
                        htmlFor="observacao"
                        className="form-field"
                      >
                          <textarea
                          type="text"
                          id="observacao"
                          name="observacao"
                          className="form-control"
                          rows={8}
                          value={observacao}
                          onChange={(e) => setObservacao(e.target.value)}
                        />
                      </FormGroup>
                    </div>
                  </div>
                {/* <FormGroup label="Foto de perfil: " htmlFor="inputFotoPerfil">
                    <FileUpload id="inputFotoPerfil" name="demo[]" uploadHandler={onUploadImagem} multiple accept="image/*" maxFileSize={1000000}
                        emptyTemplate={<p className="m-0">Arraste ou selecione uma foto.</p>} 
                        chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}
                        customUpload/>
                </FormGroup> */}

                {imagemPerfil != null && (
                  <div>
                    <h5>Perfil</h5>
                    <Image src={imagemPerfil} alt="Image" width="250" preview />
                    <Button
                      icon="pi pi-times"
                      className="p-button-rounded p-button-danger p-button-text"
                      aria-label="Cancel"
                      onClick={deleteImagemPerfil}
                    />
                  </div>
                )}

                {imagemPerfil == null && (
                  <FormGroup label="Foto de perfil: " htmlFor="inputFotoPerfil">
                    <FileUpload
                      ref={(ref) => {
                        fileUploadRef = ref;
                      }}
                      id="inputFotoPerfil"
                      name="demo[]"
                      uploadHandler={onUploadImagemPerfil}
                      accept="image/*"
                      maxFileSize={1000000}
                      emptyTemplate={emptyTemplate}
                      chooseOptions={chooseOptions}
                      uploadOptions={uploadOptions}
                      cancelOptions={cancelOptions}
                      customUpload
                    />
                  </FormGroup>
                )}
                <button
                  onClick={salvar}
                  type="submit"
                  className="btn btn-success"
                >
                  <i className="pi pi-save" /> Salvar
                </button>
                {/* <Link to="/login"> */}
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    goCancelar()
                  }}
                >
                  <i className="pi pi-times" /> Cancelar
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </Card1>
      </form>
    </S.FormularioPessoaStyle>
  );
}

export default FormularioPessoa;
