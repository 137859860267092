import styled from "styled-components";

export const FormBuscaStyle = styled.div`
  padding: 2rem 0;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 40%;

    .btn{
        margin-top: 1rem;
    }

    .btn-danger{
        margin-left: 0.8rem;
    }

    .btn-warning{
        margin-left: 0.8rem;
    }

    input {
        height: 2.3rem;
    }

    .form-group {
        padding-top: 1rem;
    }

    .p-dropdown {
        height: 2.3rem;
    }

    .p-placeholder {
        font-size: small;
        align-self: center;
    }

    .p-inputtext {
        font-size: small;
        align-self: center;
    }

    .btn-pdf {
        margin-left: 0;
    }

    .btn-table {
        margin-top: 0;
    }

    .form-container {
        margin-top: 2rem;
    }

`
