import React from "react";
import { useLocation } from "react-router-dom";
import FormBusca from "../../Components/FormBusca";
import * as S from "./style";

function BuscarPessoas() {
  const location = useLocation();
    let paginaAtual = null;
    let edit = false;
    if(location.state != null){
        paginaAtual = location.state.paginaAtual;
        edit = location.state.edit;
     }
  return (
    <S.BuscarPessoasWrapper>
      <FormBusca paginaAtual={paginaAtual} edit={edit}/>
    </S.BuscarPessoasWrapper>
  );
}

export default BuscarPessoas;