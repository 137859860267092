import styled from "styled-components";

export const FormDetalharStyle = styled.div`
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;

  .info {
    padding: 0.5rem;
  }

  button {
    padding: 1rem;
    margin-top: 2rem;
    margin-right: 1rem;
  }

  button-container {
    flex-direction: row;
    display: flex;
  }

  
  @media (min-width: 480px) {
      .detalhar-form {
        min-width: 40rem;
      }
}

  @media (min-width: 760px) {
    .detalhar-form {
        min-width: 50rem;
      }
}

  @media (min-width: 1200px) {
    .detalhar-form {
        min-width: 60rem;
      }
  }
`;