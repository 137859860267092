import jwtDecode from "jwt-decode";
import ApiService from "./Api"


export const TOKEN = "accessToken";
export const USUARIO_LOGADO = "username";

class AuthService extends ApiService{
    constructor(){
        super("/auth")
    }

    autenticar(credenciais){
        return this.post("/signin", credenciais)
    }

    static isUsuarioAutenticado(){
        const token = localStorage.getItem(TOKEN);
        if (token !== 'undefined' && token !== null){
            const claims = jwtDecode(token);
            const expiration = claims.exp;
            const isTokenInvalido = Date.now() >= (expiration * 1000);
    
            return !isTokenInvalido;
        }

        return false;
    }

    static removerUsuarioAutenticado(){
        localStorage.removeItem(USUARIO_LOGADO);
        localStorage.removeItem(TOKEN);
    }

    static logar(usuario, token){
        localStorage.setItem(USUARIO_LOGADO, usuario);
        localStorage.setItem(TOKEN, token);

        //Como pegar as permissões do usuário logado
        //const claims = jwtDecode(token);

        ApiService.registrarToken(token);
    }

    static obterItemUsuarioAutenticado(){
        return localStorage.getItem(USUARIO_LOGADO);
    }

    static obtemPermissaoUsuario(){
        const token = jwtDecode(localStorage.getItem(TOKEN));
        return localStorage.getItem(token.roles[0]);
    }

    static refreshSession(){
        const token = localStorage.getItem(TOKEN);
        const usuario = AuthService.obterItemUsuarioAutenticado();
        AuthService.logar(usuario, token);

        return usuario;
    }
}

export default AuthService