import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import moment from 'moment'
import 'moment/locale/pt'
import imagem from "../../../Imagens/brasaoNacionaljpg.jpg";


function ResidenciaPdf(pessoa){

    const ima = imagem;
    
    moment.locale('pt-br');
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const now = moment().format('LL');

    const estadoCivil =[
        "Solteiro(a)",
        "Casado(a)",
        "Divorciado(a)",
        "União Estável",
        "Viúvo(a)"
    ]


//     const reportTitle = [

        
//         {

//             image: ima,
//             margin: [60, 20, 0, 0]
            
//         },
//         // {
//         // text: 'MINISTÉRIO DA AGRICULTURA, PECUÁRIA E ABASTECIMENTO. ' + 
//         //         ' \n SECRETARIA DE AQUICULTURA E PESCA.',
        
//         // fontSize: 10,
//         // bold: true,
//         // margin: [200, 20, 0, 45], //lef, top, right, bottom
//         // },

// ];

    const details = [


        {
            text: "DECLARAÇÃO DE RESIDÊNCIA",
            alignment: 'center',
            fontSize: 10,
            bold: true,
            margin: [0, 30, 0, 0]
        },

        {
            fontSize: 10, 
            bold: true,
            margin: [20, 40, 20, 0],
            lineHeight: 1.5,
            text: [
                'Na falta de documentos próprios, aptos a comprovarem minha residência ' +
                'e domicilio, eu ',
                {text: `${pessoa.nome},`, fontSize: 10, bold: true, decoration: 'underline'},
                ' Nacionalidade: ',
                {text: `Brasileiro,`, fontSize: 10, bold: true, decoration: 'underline'},
                ' estado civil: ',
                {text: `${estadoCivil[pessoa.estadoCivil - 1]},`, fontSize: 10, bold: true, decoration: 'underline'},
                ' Profissão: ',
                {text: `${pessoa.profissao},`, fontSize: 10, bold: true, decoration: 'underline'},
                ' inscrito(a) no Cadastro de Pessoas Físicas(CPF) sob o nº: ',
                {text: `${pessoa.cpf},`, fontSize: 10, bold: true, decoration: 'underline'},
                ' portador(a) da Carteira de Identidade (RG) nº: ',
                {text: `${pessoa.rg},`, fontSize: 10, bold: true, decoration: 'underline'},
                ' declaro ser residente e domiciliado(a) no endereço: ',
                {text: `${pessoa.endereco}, Nº ${pessoa.numeroCasa === null ? "S/N" : pessoa.numeroCasa}`, fontSize: 10, bold: true, decoration: 'underline'},
                ' Bairro: ',
                {text: `${pessoa.bairro},`, fontSize: 10, bold: true, decoration: 'underline'},
                ' município: ',
                {text: `${pessoa.municipio},`, fontSize: 10, bold: true, decoration: 'underline'},
                ' UF: ',
                {text: `${pessoa.estado},`, fontSize: 10, bold: true, decoration: 'underline'},
                ' CEP: ',
                {text: `${pessoa.cep},`, fontSize: 10, bold: true, decoration: 'underline'},
                ' Telefone: ',
                {text: `${pessoa.celular},`, fontSize: 10, bold: true, decoration: 'underline'},
                ' E-mail: ',
                {text: `${pessoa.email}`, fontSize: 10, bold: true, decoration: 'underline'},
                ' .'
            ]
        },

        {
            text: 'Declaro sob a responsabiliade civil e penal, que as informações declaradas acima são verdadeiras e que ' +
                   'estou ciente que as informações não verídicas declaradas implicarão em penalidades previstas no Artigo ' +
                   '299 do Código Penal (Falsidade ideológica), além de sansões civis e administrativas cabíveis, conforme ' + 
                   'dispõe a lei nº 7.115, de 29 de agosto de 1983.',
            italics: true,
            fontSize: 10,
            margin: [20, 40, 20, 0],
            lineHeight: 1.5,
        },

        {
            text: '"Art. 299 do Código Penal Brasileiro – Omitir, em documento público ou paticular, declaração que dela ' +
                   'devia constar, ou nele inserir ou  fazer inserir delcaração falsa ou diversa da que devia ser escrita, com fim ' +
                   'de prejudicar direito, criar obrigação ou alterar a verdade sobre fato juridicamente relevante: ',
            italics: true,
            fontSize: 10,
            margin: [20, 40, 20, 0],
            lineHeight: 1.5,
        },

        {
            text: 'Pena-reclusão, de um a cinco anos, e multa, se o documento é público, e reclusão de 1 a 3 anos, e multa, se ' +
                   'o documento é particular" ',
            
            italics: true,
            fontSize: 10,
            margin: [20, 20, 20, 0],
            lineHeight: 1.5,
        },

        //data de hoje
        {
            text: `Municipio, ${now}.`,
            alignment: 'center',
            fontSize: 10,
            margin: [0, 30, 0, 0],
            lineHeight: 1.5,

        },

        {
            text: '___________________________________________________________________', 
            alignment: 'center',
            fontSize: 10,
            margin: [0, 30, 0, 0],
        },

        {
            text: 'Assinatura do Pescador ou Pescadora Profissional',
            alignment: 'center',
            fontSize: 10,
            bold: true,
            margin: [0, 20, 0, 0],

        },
            
        
    ];

    function Rodape(currentPage, pageCount){
        // return[
        //     {
        //         text: "_______________________________________ \n Assinatura do Presidente ou Responsável pela Entidade",
        //         alignment: 'center',
        //         fontSize: 9,
        //         margin: [0, 0, 0, 0]
        //     }
        // ]
    }

    const docDefinitions = {
        pageSize: 'A4',
        // pageMargins: [15, 50, 15, 40],
        // pageMargins: [40, 80, 40, 70],
        pageMargins: [15, 80, 15, 70],

        header: {
            margin: [0, 10, 0, 20],
            columns: [
                {
                    image:ima,
                    margin: [50, 10, 0, 0],
                    width: 80,
                },
                {
                    text: 'MINISTÉRIO DA AGRICULTURA, PECUÁRIA E ABASTECIMENTO. ' + 
                            ' \n SECRETARIA DE AQUICULTURA E PESCA.',
                    
                    fontSize: 10,
                    bold: true,
                    margin: [50, 40, 0, 0], //lef, top, right, bottom
                },
            ]
        },
        content: [details],
        footer: Rodape
    }

    pdfMake.createPdf(docDefinitions).open();

}

export default ResidenciaPdf;